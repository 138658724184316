<script setup lang="ts">
    import { computed } from "vue";

    const props = defineProps({
        ariaLabel: {
            type: String,
            default: "",
        },
        content: {
            type: String,
            default:""
        },
        callback: {
            type: Function,
            default() {
                return () => {}
            }
        },
        outlined: {
            type: Boolean,
            default: false
        },
        noHover: {
            type: Boolean,
            default: false,
        },
        margin: {
            type: String,
            default: ""
        },
        padding: {
            type: String,
            default: "py-2.5"
        },
        size: {
            type: String,
            default: "sm"
        },
        height: {
            type: String,
            default: 'h-14'
        },
        width: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false,
        }
    });
    
    const twClasses = computed(() => {
        if(!props.outlined) {
            return `flex align-center justify-center place-items-center text-white bg-gradient-to-br from-clive-blue to-clive-purple hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 rounded-lg font-medium text-${props.size} px-5 ${props.padding} text-center ${props.margin} ${props.height} ${props.width ? props.width : ''}`
        }
        
        if(props.noHover) {
            return `flex align-center justify-center place-items-center text-clive-blue hover:ring-2 border border-clive-blue focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-${props.size} px-5 ${props.padding} text-center ${props.margin} ${props.height} ${props.width ? props.width : ''}`;
        }
        return `flex align-center justify-center place-items-center text-clive-blue hover:text-white border border-clive-blue hover:bg-blue focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-${props.size} px-5 ${props.padding} text-center ${props.margin} ${props.height} ${props.width ? props.width : ''}`; 
    })

</script>

<template>
    <button type="button" :aria-label="ariaLabel" :disabled="disabled" :class="twClasses" @click="props.callback">
        {{ props.content }}
        <slot></slot>
    </button>
</template>